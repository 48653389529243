import Axios from "axios";

const { REACT_APP_BASE_API, REACT_APP_APPLICATION_TOKEN } = process.env;

const token = JSON.parse(localStorage.getItem("token") || "null");
const apiClient = Axios.create({
  baseURL: REACT_APP_BASE_API,
  headers: { applicationToken: REACT_APP_APPLICATION_TOKEN! },
});
apiClient.defaults.headers.common["userAuthToken"] = token;

export default apiClient;
