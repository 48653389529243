import authService from "apiClient/authService";
import { useToast } from "components/ui/use-toast";
import { persistentService } from "lib/persistentService";
import { getErrorMessage } from "lib/utils";
import { AuthRequest, User } from "models/User";
import React, { useEffect } from "react";
import { useMutation } from "react-query";

type props = {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
};

const useAuth = ({ user, setUser }: props) => {
  const { toast } = useToast();

  const { mutate: requestLogin, isLoading: loginRequestLoading } = useMutation(
    (userRequest: AuthRequest) => authService.requestLogin(userRequest)
  );
  const { mutate: verifyToken, isLoading: tokenVerficationLoading } =
    useMutation((token: string) => authService.verifyToken(token));

  const handleLogin = (userRequest: AuthRequest) => {
    requestLogin(userRequest, {
      onSuccess: (data) => {
        persistentService.setItem("token", data.token);
        setUser(data.authUser);
      },
      onError: (error) => {
        const message = getErrorMessage(error);
        toast({ description: message, variant: "destructive" });
      },
    });
  };
  const handleLogOut = () => {
    persistentService.removeItem("token");
    setUser(null);
  };
  useEffect(() => {
    const token = persistentService.getItem("token");
    if (!token) return;
    verifyToken(token, {
      onSuccess: (data) => {
        setUser(data.authUser);
      },
      onError: (error) => {
        const message = getErrorMessage(error);
        toast({ description: message, variant: "destructive" });
        persistentService.removeItem("token");
      },
    });
  }, []);

  return {
    isAuthenticated: user !== null,
    handleLogin,
    handleLogOut,
    loginRequestLoading,
    tokenVerficationLoading,
  };
};

export default useAuth;
