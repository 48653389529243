import apiClient from "./apiClient";
import { AuthRequest, AuthResponse } from "models/User";

const requestLogin = async ({ userName, password }: AuthRequest) => {
  const { data } = await apiClient.post(`v2/authUser/login`, {
    userName,
    password,
  });
  return data as AuthResponse;
};

const verifyToken = async (token: string) => {
  const { data } = await apiClient.post(
    `v2/authUser/refresh-token`,
    {},
    {
      headers: {
        userauthtoken: token,
      },
    }
  );
  return data as AuthResponse;
};

export default { requestLogin, verifyToken };
