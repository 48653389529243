import { clsx, type ClassValue } from "clsx";
import { DEVICES, INTERNAL_DEVICES, STATUS } from "models/Nina";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getErrorMessage = (err: any): string => {
  if (err?.response?.data?.message) {
    return err.response?.data?.message;
  } else if (err?.data?.message) {
    return err?.data?.message;
  } else if (err?.message) {
    return err.message;
  } else {
    return "Something went wrong";
  }
};

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number
) {
  let timeout: ReturnType<typeof setTimeout>;

  return (...args: Parameters<T>) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  };
}

export const upperCaseString = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export const camelCaseToSentenceCase = (str: string) => {
  return str.replace(/([A-Z])/g, " $1").trim();
};
export const camelCaseToTitleCase = (str: string) => {
  return upperCaseString(camelCaseToSentenceCase(str));
};

export const getDeviceType = (deviceType: string) => {
  switch (deviceType) {
    case DEVICES.NINA:
      return INTERNAL_DEVICES.NINA;
    case DEVICES.ANCHOR:
      return INTERNAL_DEVICES.ANCHOR;
    case DEVICES.DOCK:
      return INTERNAL_DEVICES.DOCK;
    default:
      return deviceType;
  }
};

export const getDeviceStatus = (status: string) => {
  switch (status) {
    case STATUS["R&D"]:
      return "RnD";
    default:
      return status;
  }
};
