import useAuth from "hooks/useAuth";
import { AuthContext } from "models/Context";
import { User } from "models/User";
import { createContext, PropsWithChildren, useContext, useState } from "react";

export const GlobalAuthContext = createContext<AuthContext>(null!);

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<User | null>(null);
  const {
    handleLogin,
    handleLogOut,
    loginRequestLoading,
    isAuthenticated,
    tokenVerficationLoading,
  } = useAuth({
    user,
    setUser,
  });
  return (
    <GlobalAuthContext.Provider
      value={{
        user,
        isAuthenticated,
        login: handleLogin,
        logout: handleLogOut,
        loginRequestLoading,
        tokenVerficationLoading,
      }}
    >
      {children}
    </GlobalAuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(GlobalAuthContext);
