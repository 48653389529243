import { Outlet } from "react-router-dom";
import Header from "components/Header";
import { useAuthContext } from "context/AuthContext";
import NinaLoader from "components/NinaLoader";

const Layout = () => {
  const { tokenVerficationLoading } = useAuthContext();
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="container px-4">
        {tokenVerficationLoading ? <NinaLoader /> : <Outlet />}
      </div>
    </div>
  );
};

export default Layout;
