import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import NinaLoader from "components/NinaLoader";
import Layout from "pages/Layout";
const LoginPage = lazy(() => import("pages/LoginPage"));
const PrivateRoute = lazy(() => import("pages/PrivateRoute"));
const HomePage = lazy(() => import("pages/HomePage"));
const NotFoundPage = lazy(() => import("pages/NotFoundPage"));

function App() {
  return (
    <Suspense fallback={<NinaLoader />}>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/login" element={<LoginPage />} />
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<HomePage />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
