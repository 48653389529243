export enum DEVICES {
  NINA = "Nina",
  ANCHOR = "Lock",
  DOCK = "Charger",
}
export enum INTERNAL_DEVICES {
  NINA = "Nina",
  ANCHOR = "Anchor",
  DOCK = "Dock",
}

export enum STATUS {
  OK = "OK",
  MALFUNCTION = "Malfunction",
  ON_HOLD = "On hold",
  OUT_OF_USE = "Out of use",
  "R&D" = "R&D",
}
