import { useAuthContext } from "context/AuthContext";
import { LogOutIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "./ui/button";

const Header = () => {
  const { user, logout } = useAuthContext();
  const navigate = useNavigate();
  return (
    <header className="w-full bg-gradient-custom sticky top-0 z-10">
      <div className="container px-4 flex items-center justify-between py-2">
        <img
          src="https://v2-venues-logos.s3.us-west-1.amazonaws.com/Nina/NINA_NEW_LOGO+FINAL-cream.png"
          alt="ninaLogo"
          className="h-12 w-12 cursor-pointer"
          onClick={() => navigate("/")}
        />
        <h1 className="text-cream text-2xl font-semibold uppercase flex-grow text-center">
          Nina Dashboard
        </h1>
        {user !== null && (
          <div className="flex items-center gap-1">
            <p className="text-s text-cream hidden md:block">
              Welcome {user.userName || "User"}
            </p>
            <Button onClick={logout} className="p-2" variant="ghost">
              <LogOutIcon className="h-4 w-4 text-cream" />
            </Button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
